import React, { useContext } from "react";

export type PageContext = {
  pageData?: Record<string, any>;
};

const Context = React.createContext<PageContext>(undefined as any);

type PageContextProviderProps = {
  pageContext: PageContext;
  children: React.ReactNode;
};
export function PageContextProvider({ pageContext, children }: PageContextProviderProps) {
  return <Context.Provider value={pageContext}>{children}</Context.Provider>;
}

export function usePageContext() {
  const pageContext = useContext(Context);
  return pageContext;
}

/**
 * By convention, this will be used with React router to make fetched data available
 * to the whole component tree.
 */
export function usePageData() {
  const pageContext = useContext(Context);
  return pageContext.pageData;
}

export const usePage = usePageContext;
export default usePage;
