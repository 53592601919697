import { applications } from "@sciam/auth0";
import { useFlags } from "~core/hooks/use-flags";

const auth0Apps = Object.fromEntries(applications.map((app) => [app.slug, app]));

/**
 * Get the Auth0 app configuration based on the current environment
 * @param {boolean} [immediate=false]
 *   Whether to check for override flags *before* hydration.
 *   Only use this to configure Auth0, which has to be hardcoded at first render before the DOM loads.
 *   **Do not** use it for any reactive UI.
 * @returns {typeof applications[number]}
 */
export function useAuth0Env(immediate = false) {
  const { auth0 } = useFlags(immediate) || {};
  return auth0Apps[auth0] || auth0Apps[import.meta.env.PUBLIC_AUTH0_APP] || auth0Apps.prod;
}

export default useAuth0Env;
