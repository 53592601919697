/**
 * This is a collection of error codes found in Auth0's docs with corresponding user-friendly names.
 */
export const AUTH0_ERROR_STATES = /** @type {const} */ ({
  success: "Sign In Successful",

  // 400 - Invalid request body. All and only of client_id, credential_type, username, otp, realm are required.
  // 400 - Unknown realm non-existent-connection.
  invalid_request: "Invalid Request",
  // 401 - Cross origin login not allowed.
  unauthorized_client: "Unauthorized Client",
  // 400 - Unknown credential type parameter.
  unsupported_credential_type: "Unsupported Credential Type",
  // 403 - Wrong email or password.
  // 403 - Authentication error
  access_denied: "Access Denied",
  // 403 - Blocked user
  blocked_user: "Blocked",
  // 401 - This login attempt has been blocked because the password you're using was previously disclosed through a data breach (not in this application).
  password_leaked: "Password Leaked",
  // 429 - Your account has been blocked after multiple consecutive login attempts. We've sent you a notification via your preferred contact method with instructions on how to unblock it.
  // 429 - We have detected suspicious login behavior and further attempts will be blocked. Please contact the administrator.
  too_many_attempts: "Too Many Attempts",

  // Other possible errors found in native app auth flows
  slow_down: "Slow Down",
  authorization_pending: "Pending",
  login_required: "Login Required",
  unauthorized: "Unauthorized",
  invalid_token: "Invalid Token",
});

/**
 * @typedef {{
 *   code: string;
 *   level: "error" | "warn";
 *   internalMessage: string;
 *   userMessage: string;
 * }} AuthSyncException
 */

/**
 * Auth Sync-related exceptions
 * Represents the error level, internal message, and user-facing message
 * @satisfies {Record<string, AuthSyncException>}
 */
export const AUTH_SYNC_EXCEPTIONS = /** @type {const} */ ({
  /**
   * User's Auth0 environment isn't compatible with the Piano environment
   */
  auth_env_mismatch: {
    code: "ENV_MISMATCH",
    level: "warn",
    internalMessage: "Environment Mismatch",
    userMessage: "Environment Mismatch Warning",
    troubleshooting:
      "Your Piano environment is not compatible with the current Auth0 application. This may result in unexpected behavior.",
  },
  /**
   * User is logged in according to either Piano or Auth0, but not both
   */
  login_mismatch: {
    code: "LOGIN_MISMATCH",
    level: "warn",
    internalMessage: "Sign In Mismatch",
    userMessage: "There may be a problem loading your account",
    troubleshooting:
      "Please try logging out and back in. If problems persist, please contact customer service.",
  },
  /**
   * Piano Auth Sync JWT is missing.
   *
   * This could either be Piano's domain being blocked by the user's browser or simply that the JWT
   * wasn't set properly, which we generally recover from by the next page load.
   */
  piano_jwt_missing: {
    code: "PAS_MISSING",
    level: "error",
    internalMessage: "Piano JWT missing",
    userMessage: "Error loading your subscription",
    troubleshooting:
      "Please reload the page. If problems persist, you may need to disable content blockers and/or privacy extensions to access your subscription.",
  },
  /**
   * Auth0 JWT is missing
   *
   * This could be due to a bug in the login flow or a temporary migration bug.
   */
  auth0_jwt_missing: {
    code: "AUTH0_JWT_MISSING",
    level: "error",
    internalMessage: "Auth0 JWT missing",
    userMessage: "Error loading your account",
    troubleshooting:
      "Try logging out and back in. If problems persist, please contact customer service.",
  },
  /**
   * The UIDs between Auth0 and Piano don't match. Possible causes include
   * - a user migration bug
   * - staff swapping between Auth0 environments resulting in de-synced cookies
   * - changes to the user's email address ???
   * - a user logging in for the first time with a different auth method (highly unlikely)
   */
  uid_mismatch: {
    code: "UID_MISMATCH",
    level: "warn",
    internalMessage: "User ID mismatch",
    // These errors are only visible to staff for the forseeable future.
    // @TODO: If we decide to show these to users, we need to swap it back to the original copy.
    userMessage: "Auth0 and Piano user IDs don't match",
    troubleshooting:
      "This is expected behavior for staff users testing their @sciam.com emails outside production.",
    // userMessage: "There may be a problem loading your account",
    // troubleshooting: "Please try reloading the page or logging out and back in.",
  },
  /**
   * The emails between Auth0 and Piano don't match. Maybe the user's email address changed?
   */
  email_mismatch: {
    code: "EMAIL_MISMATCH",
    level: "error",
    internalMessage: "Email mismatch",
    userMessage: "Error loading your login information",
    troubleshooting: "Please try logging out and back in.",
  },
});
